
import Vue from 'vue';
import { CustomerEnum } from "@/types/enums"
import { Person, SnackbarInfo } from "@/types/responses"


export default Vue.extend({
	name: "RemovePersonDialog",
	props: {
		person: {
			type: Object,
		},
		remove: {
			type: Boolean,
		},
        clientPersons: {
            type: Boolean,
            required: true
        },
        isLastMember: {
            type: Boolean
        }
	},
	data(): {
		snackBar: boolean
		snackBarText: string
		loading: boolean
		enm: typeof CustomerEnum
		timeLeft: number
	} {
		return {
			snackBar: false,
			snackBarText: "",
			loading: false,
			enm: CustomerEnum,
			timeLeft: 2,
		}
	},
	methods: {
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
		cancel(): void {
			if(!this.loading){
				this.$emit("close", null)
			}
		},

        async removeUser(person: Person): Promise<void> {

            if(this.clientPersons) await this.removeClientPerson(person)
            else await this.removeCustomerPerson(person)
    
        },
        async removeClientPerson(person: Person): Promise<void> {
            if(this.isLastMember){
                this.$emit('removeLastMember', null)
                console.log("Emitting. Removing the last member and continuing removal of client.")
            }
			this.loading = true
			let snackbarInfo: SnackbarInfo;
			try {
				await this.$vStore.dispatch("context/removeClientPerson", person.id)
				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully removed " + this.person.displayName + ".",
					e: null
				}
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not remove person.",
					e: e
				}
			}

			this.loading = false
			this.$emit('showSnackbarInfo', snackbarInfo)
			this.cancel()
		},
		async removeCustomerPerson(person: Person): Promise<void> {
			this.loading = true
			let snackbarInfo: SnackbarInfo;
			try {
				await this.$vStore.dispatch("context/removeCustomerPerson", person.id)
				await this.countDownTimer()
				this.$emit('silentRefresh', null)
				snackbarInfo = {
					success: true,
					msg: "Successfully removed " + this.person.displayName + ".",
					e: null
				}
			} catch (e) {
				console.warn(e)
				snackbarInfo = {
					success: false,
					msg: "Could not remove person.",
					e: e
				}
			}

			this.loading = false
			this.$emit('showSnackbarInfo', snackbarInfo)
			this.cancel()
		},
	},
})
